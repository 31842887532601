<template>
  <v-card
    v-if="
      GROUP_CATEGORY.EVENT.includes(
        product?.product_category?.productcategory_id
      )
    "
    max-width="400"
    max-height="400"
    class="ma-4 pb-2"
    height="300"
    :disabled="product?.quantity < 1 && product?.is_limited == true"
    width="200"
    :to="`/detail/${product.product_id}`"
  >
    <v-img
      @contextmenu.prevent
      :src="product?.product_details[0].productdetail_image_url"
      :lazy-src="product?.product_details[0].productdetail_image_url"
      height="60%"
      cover
      :class="{ bnw: is_upcoming }"
      class="align-start"
    >
      <div
        v-if="product?.quantity > 0 || product?.is_limited == false"
        class="bg-tertiary-dark rounded-be-lg d-inline-block"
      >
        <p
          class="pa-2 text-bg-primary-dark text-body-2 font-weight-bold d-inline-block text-truncate"
          :style="{ 'max-width': '150px' }"
        >
          {{ product?.store_name }}
        </p>
      </div>

      <div v-else class="bg-red rounded-be-lg d-inline-block">
        <p class="pa-2 text-bg-white text-body-2 font-weight-bold">
          Habis Terjual
        </p>
      </div>

      <template v-slot:placeholder>
        <v-row align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular
            color="grey lighten-5"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-card-title
      class="text-body-2 font-weight-bold text-capitalize text-bg-background"
    >
      {{ product?.product_name }}
      <v-tooltip
        content-class="bg-grey"
        activator="parent"
        location="end center"
        origin="overlap"
      >
        {{ product?.product_name }}
      </v-tooltip>
    </v-card-title>

    <v-card-text class="py-1">
      {{ utcToLocale(product?.event_start, "DD MMM") }} -
      {{ utcToLocale(product?.event_end, "DD MMM YYYY") }}
    </v-card-text>

    <v-card-subtitle class="text-secondary-60 font-weight-bold">
      Rp. {{ product?.price_min?.toLocaleString() }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { utcToLocale } from "@/utils/dateFormatter";
import { GROUP_CATEGORY } from "@/config/category";

export default {
  name: "ConcertCard",

  data() {
    return {
      GROUP_CATEGORY: GROUP_CATEGORY,
    };
  },

  props: ["product", "is_upcoming"],
  created() {},

  methods: {
    utcToLocale(isoDate, format) {
      return utcToLocale(isoDate, format);
    },
  },
};
</script>

<style >
.bnw {
  filter: grayscale(100%);
}
</style>